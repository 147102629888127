import React, { useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { ArrowLeftCircle, ArrowRightCircle, PersonFill } from 'react-bootstrap-icons';

import { SimpleModal } from 'components/modal';

import type { CrewInterface, DrivingTimesInterface, ProjectInterface } from '../logistics-tool/types';
import type { TripContentInterface } from './types';

export const LogisticsGridTripsContent: React.FC<TripContentInterface> = ({
  logistic,
  allCrews,
  allTimes,
  selectedCrews,
  deliverProjects,
  returnProjects,
  selectedProjects,
}) => {
  const initalProjectData: ProjectInterface = {
    projects_id: 0,
    projects_customer_id: 0,
    projects_customer_name: '',
    projects_crew_name: '',
    projects_status: '',
    projects_department: '',
    projects_driving_times: [],
    projects_address: '',
    projects_zipcode: 0,
    projects_city: '',
    projects_amount: 0,
    projects_delivery_date: '',
    projects_used_date: '',
    projects_type: '',
    files: [],
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [project, setProject] = useState<ProjectInterface>(initalProjectData);

  const findCrew = (crewId: number): CrewInterface | null => {
    let foundCrew: CrewInterface | null = null;

    for (const crew of allCrews) {
      if (crew.crew_id === crewId) {
        foundCrew = crew;
      }
    }

    return foundCrew;
  };

  const findDrivingTimes = (drivingTimeId: number): DrivingTimesInterface | null => {
    let foundDrivingTimes: DrivingTimesInterface | null = null;

    for (const time of allTimes) {
      if (time.driving_times_id === drivingTimeId) {
        foundDrivingTimes = time;
      }
    }

    return foundDrivingTimes;
  };

  const findProject = (projectId: number): ProjectInterface | null => {
    let foundProject: ProjectInterface | null = null;

    for (const tmpProjectId of selectedProjects) {
      if (deliverProjects.some((project) => project.projects_id === tmpProjectId) === true) {
        for (const project of deliverProjects) {
          if (project.projects_id === projectId) {
            foundProject = { ...project, deliverType: 'delivery' };
          }
        }
      }

      if (returnProjects.some((project) => project.projects_id === tmpProjectId) === true) {
        for (const project of returnProjects) {
          if (project.projects_id === projectId) {
            foundProject = { ...project, deliverType: 'returning' };
          }
        }
      }
    }

    return foundProject;
  };

  const handleOpenProject = async (project: ProjectInterface): Promise<void> => {
    setProject(project);
    setShowModal(true);
  };

  const handleCloseProject = async (): Promise<void> => {
    setProject(initalProjectData);
    setShowModal(false);
  };

  return (
    <>
      <Row className="mb-2">
        <Col sm={12}>
          {selectedCrews.length !== 0 &&
            selectedCrews.map((crewId, index) => {
              const crew = findCrew(crewId);

              if (crew) {
                return (
                  <Alert key={`${logistic.logistics_id}-${crewId}-${index}`} variant="secondary">
                    <PersonFill /> {crew.crew_name}
                  </Alert>
                );
              } else {
                return null;
              }
            })}
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          {selectedProjects.length !== 0 &&
            selectedProjects.map((projectId, index) => {
              const project = findProject(projectId);

              if (project) {
                return (
                  <Alert
                    key={`${logistic.logistics_id}-${projectId}-${index}`}
                    variant={project.deliverType === 'delivery' ? 'success' : 'danger'}
                    onClick={() => handleOpenProject(project)}
                  >
                    {project.deliverType === 'delivery' ? <ArrowRightCircle /> : <ArrowLeftCircle />} {project.projects_customer_name} <br />{' '}
                    {project.projects_address} <br /> {project.projects_zipcode} {project.projects_city} <br />
                    {project.projects_driving_times.length !== 0 &&
                      project.projects_driving_times.map((drivingTimeId) => {
                        const foundDrivingTimes = findDrivingTimes(drivingTimeId);
                        if (foundDrivingTimes) {
                          return <span key={foundDrivingTimes.driving_times_id}>{foundDrivingTimes.driving_times_name}</span>;
                        }
                        return null;
                      })}
                  </Alert>
                );
              }
            })}
        </Col>
      </Row>

      <SimpleModal
        title={`${project?.projects_customer_name}`}
        showModal={showModal}
        showSaveButton={false}
        showCloseButton={true}
        closeButtonText={'Luk'}
        closeCallback={handleCloseProject}
      >
        <Row>
          {project.files.length > 0
            ? project.files.map((file, index) => (
                <Row key={index}>
                  <Col sm={12}>
                    <p>{file.project_files_name}</p>
                    <embed src={file.project_files_url + '#zoom=100'} type="application/pdf" width="100%" height="800px"></embed>
                  </Col>
                </Row>
              ))
            : null}
        </Row>
      </SimpleModal>
    </>
  );
};
